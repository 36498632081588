import { getHomeLayout } from '@/layout'
import { graphcmsClient } from '@/graphql/_client'
import _ from 'lodash'

import { parsePageData } from '@/utils/_parsePageData'
import { parseArticles } from '@/utils/_parseArticles'

import { getHomePageIndexQuery } from '@/graphql/queries'
import { MDXRemote } from 'next-mdx-remote'

import { Box } from '@chakra-ui/react'

import { HomeSectionsContainer } from '@/containers'
function HomePage({
  page,
  featuredArticles,
  mostPopularArticles,
  latestArticles
}) {
  return (
    <Box m={0} className="HOME_PAGE" w="100%">
      {page?.pageHeadline && (
        <Box
          m={0}
          p={0}
          centerContent
          color="gray.500"
          lineHeight="tall"
          fontSize="xl"
        >
          <MDXRemote {...page.pageHeadline.mdx} />
        </Box>
      )}
      {page?.pageSubheadline && (
        <Box
          p={0}
          centerContent
          color="gray.500"
          lineHeight="tall"
          fontSize="xl"
        >
          <MDXRemote {...page.pageSubheadline.mdx} />
        </Box>
      )}

      {page?.pageSections && (
        <HomeSectionsContainer
          featuredArticles={featuredArticles}
          mostPopularArticles={mostPopularArticles}
          latestArticles={latestArticles}
          sections={page.pageSections}
        />
      )}
    </Box>
  )
}
export default HomePage
export async function getStaticProps({ preview = false }) {
  const client = graphcmsClient(preview)
  const { page, featuredArticles, latestArticles, mostPopular } =
    await client.request(getHomePageIndexQuery)

  const parsedPageData = await parsePageData(page)
  const parsedFeaturedArticles = await parseArticles(featuredArticles)
  const parsedPopArticles = await parseArticles(mostPopular)
  const parsedLatestArticles = await parseArticles(latestArticles)
  const orderFeaturedArticles = _.orderBy(
    parsedFeaturedArticles,
    ['sortRank'],
    ['asc']
  )

  const orderLatestArticles = _.orderBy(
    parsedLatestArticles,
    ['publishedOn', 'sortRank'],
    ['desc', 'asc']
  )
  const mainFeaturedArticles = _.take(orderFeaturedArticles, 5)

  const remainFeaturedArticles = _.drop(orderFeaturedArticles, 5)

  const remaingArticles = [...remainFeaturedArticles, ...orderLatestArticles]

  const remainOrderArticles = _.orderBy(
    remaingArticles,
    ['publishedOn', 'sortRank'],
    ['desc', 'asc']
  )

  return {
    props: {
      page: parsedPageData,
      featuredArticles: mainFeaturedArticles,
      mostPopularArticles: parsedPopArticles,
      latestArticles: remainOrderArticles
    },
    revalidate: 60
  }
}

HomePage.getLayout = getHomeLayout
